import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import dataReducer from "./data/dataReducer";
import dataSubReducer from "./datasub/datasubReducer";
import dataBaseReducer from "./database/databaseReducer";
import dataBurnReducer from "./databurn/databurnReducer";

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  data: dataReducer,
  datasub: dataSubReducer,
  database: dataBaseReducer,
  databurn: dataBurnReducer,
});

const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;
